export function Protein() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9595 5.5083C19.9595 5.5083 10.5404 2.03397 7.57758 12.2621C7.57758 12.2621 17.511 15.8792 19.9595 5.5083Z"
        fill="#C2DA96"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7.57758 12.2621L24.6 3.60001"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M15.7256 25.0433C14.5232 24.6486 15.3829 24.9309 14.1805 24.5363C13.474 25.2087 13.2544 25.3569 12.8265 25.6022C10.8244 26.7503 8.71083 25.4684 7.82464 24.7911C6.64928 23.8926 6.7288 22.3444 6.74575 22.0145C6.76233 21.6917 6.82511 20.4695 7.84465 19.5855C8.98174 18.5998 10.0384 18.8771 10.5682 18.8281C10.5373 18.3796 10.5094 17.9758 10.4785 17.5273C10.0608 17.3969 8.99996 17.3119 8.16816 17.4307C5.98799 17.7424 5.23716 19.5772 5.11997 19.7846C3.99681 21.7726 4.45769 24.3064 5.77791 25.7794C6.84177 26.9662 8.32329 27.3087 8.69989 27.3958C9.00184 27.4655 9.89964 27.5246 11.6952 27.6426C12.7313 27.7107 13.3048 27.7358 14.0772 28.0248C14.2292 28.0818 14.8037 28.2443 15.4024 28.7128C16.0353 29.2081 16.3005 29.6351 16.8956 30.0177C17.9452 30.6928 18.7684 30.7608 19.8283 30.5969C20.6614 30.4679 22.1146 29.8885 22.9797 28.5693C22.3936 28.0521 22.1995 27.7145 21.6267 27.1714C21.4446 27.4332 21.1692 27.7315 20.5923 27.9906C19.4071 28.5229 18.295 28.2575 18.1251 28.2044C17.0275 27.8621 16.3927 26.982 16.1405 26.531C15.8523 26.0156 15.7834 25.3582 15.7256 25.0433Z"
        fill="#D2451E"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M39.2554 22.5091C39.4465 23.2464 39.3289 23.9895 38.9951 24.6156C39.0399 24.6925 39.0842 24.7696 39.1353 24.8414C40.0823 24.833 40.9641 25.2855 41.506 26.0441C41.7471 26.042 41.9904 26.0204 42.2346 25.9552C43.7925 25.5389 44.7284 23.9398 44.3252 22.3835C43.922 20.8271 42.3322 19.9029 40.7743 20.3193C39.9545 20.5383 39.3148 21.0888 38.944 21.7844C39.0745 22.0082 39.1871 22.2455 39.2554 22.5091Z"
        fill="#438442"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.7044 20.4448C37.2623 20.0285 38.8521 20.9527 39.2554 22.5091C39.6586 24.0654 38.7226 25.6645 37.1647 26.0808C35.6068 26.4971 34.017 25.5729 33.6138 24.0166C33.2105 22.4602 34.1466 20.8611 35.7044 20.4448Z"
        fill="#438442"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.9577 26.9946C41.5925 25.5851 40.2537 24.7053 38.8472 24.8588C38.4637 25.4363 37.8853 25.8883 37.1647 26.0808C37.0176 26.1201 36.8709 26.1362 36.7242 26.1523C36.2787 26.8231 36.0994 27.6656 36.3161 28.5021C36.7194 30.0583 38.3091 30.9825 39.867 30.5663C41.4248 30.15 42.3609 28.5509 41.9577 26.9946Z"
        fill="#438442"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.7243 26.1523C35.8437 26.2484 34.997 25.938 34.392 25.3444C33.9363 25.7512 33.5763 25.8994 33.5763 25.8994L28.8778 28.3425C28.4503 29.0582 29.2109 30.0579 29.2109 30.0579C30.0697 30.8463 30.8734 30.5875 30.8734 30.5875L34.5382 28.9246C35.2784 28.6424 35.9098 28.4903 36.3031 28.4121C36.1214 27.6056 36.2947 26.7991 36.7243 26.1523Z"
        fill="#438442"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M33.8805 3.37845C33.68 3.37833 31.1195 3.41557 29.1063 5.26679C27.2651 6.95963 26.7233 9.39575 26.5765 10.2864C26.2752 12.1144 26.6796 13.2454 25.7679 14.1132C24.9587 14.8836 23.9711 14.6288 23.7804 15.2065C23.7471 15.3076 23.7486 15.4221 23.7692 15.5424C22.1539 16.4158 19.6526 17.7552 19.6173 17.695C19.235 17.0424 18.4043 16.8187 17.7617 17.1952C17.1191 17.5716 16.908 18.4056 17.2902 19.0581C17.5401 19.4847 17.551 19.5384 18.1667 19.89C18.0953 20.3275 18.2017 20.6141 18.4516 21.0407C18.8339 21.6932 19.6974 21.9676 20.3072 21.5405C21.1692 20.937 20.7589 19.6895 20.7787 19.6775C20.7854 19.6734 23.535 18.0448 25.0818 17.1286C25.4138 17.3437 25.7812 17.5227 26.1648 17.6254C30.8259 18.8741 36.6234 16.4371 38.5173 14.1304C38.9686 13.5809 41.1294 10.9493 40.3091 7.91784C39.5522 5.12077 36.6351 3.37897 33.8805 3.37845Z"
        stroke="#323E5A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M25.7679 14.1133C26.6796 13.2455 26.2752 12.1144 26.5765 10.2864C26.7234 9.39573 27.2651 6.95965 29.1063 5.26678C31.1196 3.4156 33.6801 3.37841 33.8805 3.37844C36.6351 3.37904 39.5523 5.1208 40.3091 7.91793C41.1294 10.9494 38.9686 13.5809 38.5174 14.1304C36.6233 16.4371 30.8259 18.8742 26.1648 17.6255C24.7747 17.2531 23.542 15.9291 23.7804 15.2067C23.9711 14.6289 24.9586 14.8836 25.7679 14.1133Z"
        fill="#D69E25"
        stroke="#323E5A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M44.6825 34.0004H2.65337L2.27011 34.0004C2.21052 33.9936 1.98006 34.0783 2.00139 34.3223C2.02958 34.6448 3.16985 39.429 8.06879 42.7441C8.06879 42.7441 14.1383 47.0157 22.4925 47C30.8466 46.9842 35.3539 45.6906 39.2619 43.2363C43.1699 40.7821 45.3849 36.8915 45.9241 34.7824C45.9241 34.7824 46.0745 34.2369 45.9519 34.1344C45.8586 34.0565 45.8056 34.0252 45.5614 34.0004H44.6825Z"
        fill="#93BDDA"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
