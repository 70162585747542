export function Vegetarian() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6973 17.9037V21.7622L27.4654 24.8515V20.627"
        fill="#F9EED8"
      />
      <path
        d="M19.6973 17.9037V21.7622L27.4654 24.8515V20.627"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M19.8273 17.7819L27.4165 20.695L30.8701 13.5719L23.897 10.8L19.8273 17.7819Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M24.2245 14.3953C24.6288 14.1581 25.3514 13.8132 26.2887 13.7726C27.0218 13.7408 27.6059 13.9074 27.8492 13.9789"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M23.2112 17.5328C23.586 17.2508 24.265 16.8252 25.1917 16.6774C25.9165 16.5618 26.5156 16.6603 26.7654 16.7034"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M31 18.6L27.5 25V20.4L31 13.5V18.6Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
      />
      <path
        d="M19.9595 5.5083C19.9595 5.5083 10.5404 2.03397 7.57758 12.2621C7.57758 12.2621 17.511 15.8792 19.9595 5.5083Z"
        fill="#C2DA96"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7.57756 12.2621L26.2637 2.02778"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M41.512 14.2836C40.8098 15.843 36.4443 13.6397 34.0246 10.0028C33.7142 9.54862 33.8745 9.38714 34.5074 9.43356C38.322 9.71333 42.1019 13.0716 41.512 14.2836Z"
        fill="#D69E25"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M29.4054 6.87681C31.0128 8.30982 34.1999 4.55628 33.5014 3.81757C32.663 2.93071 26.9588 4.91504 29.4054 6.87681Z"
        fill="#D69E25"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M15.7256 25.0433C14.5232 24.6486 15.3829 24.9309 14.1805 24.5363C13.474 25.2087 13.2544 25.3569 12.8265 25.6022C10.8244 26.7503 8.71083 25.4684 7.82464 24.7911C6.64928 23.8926 6.7288 22.3444 6.74575 22.0145C6.76233 21.6917 6.82511 20.4695 7.84465 19.5855C8.98174 18.5998 10.0384 18.8771 10.5682 18.8281C10.5373 18.3796 10.5094 17.9758 10.4785 17.5273C10.0608 17.3969 8.99996 17.3119 8.16816 17.4307C5.98799 17.7424 5.23716 19.5772 5.11997 19.7846C3.99681 21.7726 4.45769 24.3064 5.77791 25.7794C6.84177 26.9662 8.32329 27.3087 8.69989 27.3958C9.00184 27.4655 9.89964 27.5246 11.6952 27.6426C12.7313 27.7107 13.3048 27.7358 14.0772 28.0248C14.2292 28.0818 14.8037 28.2443 15.4024 28.7128C16.0353 29.2081 16.3005 29.6351 16.8956 30.0177C17.9452 30.6928 18.7684 30.7608 19.8283 30.5969C20.6614 30.4679 22.1146 29.8885 22.9797 28.5693C22.3936 28.0521 22.1995 27.7145 21.6267 27.1714C21.4446 27.4332 21.1692 27.7315 20.5923 27.9906C19.4071 28.5229 18.295 28.2575 18.1251 28.2044C17.0275 27.8621 16.3927 26.982 16.1405 26.531C15.8523 26.0156 15.7834 25.3582 15.7256 25.0433Z"
        fill="#D2451E"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M39.4523 21.2339C39.7932 21.915 39.8334 22.6663 39.6376 23.3483C39.6975 23.4142 39.7569 23.4804 39.8219 23.5399C40.7463 23.334 41.7031 23.5924 42.3914 24.2211C42.6268 24.1687 42.8603 24.0968 43.0854 23.982C44.522 23.2496 45.1035 21.4904 44.3842 20.0525C43.665 18.6147 41.9172 18.0428 40.4806 18.7752C39.7246 19.1606 39.214 19.8326 38.9965 20.5902C39.1708 20.7818 39.3305 20.9904 39.4523 21.2339Z"
        fill="#438442"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5487 19.9565C36.9853 19.2242 38.733 19.796 39.4523 21.2339C40.1716 22.6717 39.5901 24.431 38.1535 25.1634C36.7168 25.8958 34.9691 25.3239 34.2498 23.8861C33.5305 22.4482 34.112 20.6889 35.5487 19.9565Z"
        fill="#438442"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.0316 25.0563C42.3802 23.7541 40.8872 23.1732 39.5437 23.6171C39.2893 24.2619 38.818 24.8247 38.1535 25.1634C38.0179 25.2325 37.8777 25.279 37.7377 25.3253C37.442 26.0744 37.4426 26.9357 37.8291 27.7085C38.5484 29.1462 40.2961 29.7181 41.7327 28.9858C43.1693 28.2535 43.7509 26.4941 43.0316 25.0563Z"
        fill="#438442"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.7377 25.3252C36.8966 25.6031 36.0038 25.4763 35.2882 25.0221C34.9274 25.5151 34.6063 25.7352 34.6063 25.7352L30.5215 29.1054C30.2528 29.8947 31.2054 30.7135 31.2054 30.7135C32.2098 31.3052 32.9418 30.8843 32.9418 30.8843L36.1786 28.4929C36.8436 28.0623 37.4293 27.7818 37.7976 27.6232C37.4516 26.8724 37.4526 26.0475 37.7377 25.3252Z"
        fill="#438442"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M44.6825 34.0004H2.65337L2.27011 34.0004C2.21052 33.9936 1.98006 34.0783 2.00139 34.3223C2.02958 34.6448 3.16985 39.429 8.06879 42.7441C8.06879 42.7441 14.1383 47.0157 22.4925 47C30.8466 46.9842 35.3539 45.6906 39.2619 43.2363C43.1699 40.7821 45.3849 36.8915 45.9241 34.7824C45.9241 34.7824 46.0745 34.2369 45.9519 34.1344C45.8586 34.0565 45.8056 34.0252 45.5614 34.0004H44.6825Z"
        fill="#93BDDA"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
