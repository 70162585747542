export function GlutenFree() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.29207 19C4.29207 10.877 10.877 4.29208 19 4.29208C22.835 4.29208 26.3271 5.75982 28.9454 8.1642C31.8729 10.8526 33.7079 14.712 33.7079 19C33.7079 27.123 27.123 33.7079 19 33.7079C10.877 33.7079 4.29207 27.123 4.29207 19Z"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14.4775 25.255C11.2815 25.255 9.22454 22.824 9.22454 19.05C9.22454 15.293 11.2985 12.845 14.4775 12.845C16.9765 12.845 18.8635 14.511 19.1695 16.925L16.7215 17.316C16.4665 15.956 15.6505 15.14 14.4775 15.14C12.7605 15.14 11.7405 16.602 11.7405 19.05C11.7405 21.498 12.7605 22.96 14.4945 22.96C15.8885 22.96 16.7215 22.144 16.7385 20.733H14.6135V18.71H19.1185V20.325C19.1185 23.3 17.2825 25.255 14.4775 25.255ZM21.0284 25V13.1H28.6954V15.412H23.5444V18.319H28.3384V20.529H23.5444V25H21.0284Z"
        fill="#2C3650"
      />
    </svg>
  );
}
