import { useState } from 'react';
import { produce } from 'immer';
import { Heading, HeadingVariant, Paragraph, Text } from '@hungryroot/ramen';
import { Accordion } from '../Accordion/Accordion';
import styles from './Faq.module.css';

const ACCORDION_SECTIONS = [
  {
    title: 'How does it work?',
    content: (
      <>
        You tell us about your goals, preferences, and budget, and we recommend
        groceries and recipes we think you’ll love. You can always edit your
        delivery to get exactly what you’d like. Then comes the exciting part:
        we deliver your groceries and recipes straight to your door! Each week,
        we’ll offer new suggestions based on your tastes, from quick breakfasts
        to nourishing snacks to easy 4-ingredient dinners. Getting hungry yet?
      </>
    ),
  },
  {
    title: 'What foods will I get?',
    content: (
      <>
        That’s entirely up to you! You can take our recommendations or choose
        from ~1,000 groceries and over 10,000 chef-crafted recipes. You’ll find
        eats from fan-favorite brands, like Siggi’s, Kevin’s, and Annie’s, and
        up-and-coming brands we think you’ll love. Our foods always meet our
        super-high standards: made from whole, trusted ingredients and free from
        partially hydrogenated oils, high-fructose corn syrup, and artificial
        colors, sweeteners, and preservatives. Curious about what exactly we
        offer? Browse our <a href="/recipes/">Cookbook</a> to see some of our
        tastiest recipes.
      </>
    ),
  },
  {
    title: 'How much does it cost?',
    content: (
      <>
        You choose exactly how much food you want, and the more you add to your
        delivery, the more you save. (We bet your grocery store doesn’t offer
        that!) You can get dinners as low as $8.99 per serving, lunch for $5.99,
        and breakfast for $3.99. We also offer our Best Price Program, which
        gives you access to brands you love — like Annie’s, Koia, Harvest Snaps,
        and more — at the cheapest prices you’ll find anywhere. (Did you know
        that Hungryroot customers save up to 40% each week by ditching takeout
        and reducing food waste? Nice!)
      </>
    ),
  },
  {
    title: 'Do you have food for kids?',
    content: (
      <>
        Absolutely! We have staples (like milk and bread), tons of easy,
        packable snacks (like puffs, pouches, and bars), and quick,
        crowd-pleasing recipes like chicken nuggets and mac & cheese.
      </>
    ),
  },
  {
    title: 'Is there any commitment?',
    content: (
      <>
        Nope! We’re all about flexibility and convenience — not just when it
        comes to healthy living, but with your subscription, too. Skip or
        reschedule your weekly delivery whenever you’d like. Need to pause or
        cancel? No problem — we’ve got you. Just visit your Account Details or
        contact us at{' '}
        <a href="mailto:help@hungryroot.com">help@hungryroot.com</a>.
      </>
    ),
  },
  {
    title: 'Where do you deliver?',
    content: (
      <>
        We deliver to most zip codes in the 48 contiguous states and Washington
        D.C. Unfortunately, we don't deliver to Hawaii and Alaska, P.O. Boxes,
        or APO/FPO/DPO. We're working on making it possible to deliver to all
        zip codes across the country in the future!
      </>
    ),
  },
];

const SUPPORT_URL =
  'https://support.hungryroot.com/?_gl=1*on92vd*_ga*MjkxMDYwMTQ5LjE2OTcwNTYwNDU.*_ga_LH4B8FYSQ2*MTcwNzUwODk2Mi4xMTEuMC4xNzA3NTA4OTY1LjU3LjAuMA..';

type Props = {
  sections?: typeof ACCORDION_SECTIONS;
};

export function Faq({ sections = ACCORDION_SECTIONS }: Props) {
  const [open, setOpen] = useState<Record<string, boolean>>({});
  return (
    <section className={styles.container}>
      <Heading className={styles.header} variant={HeadingVariant.HeroAlt}>
        Your questions, answered
      </Heading>
      <div className={styles['accordion-container']}>
        {sections.map(({ title, content }) => {
          return (
            <Accordion
              className={styles.accordion}
              key={title}
              isExpanded={open[title] === true}
              onToggle={({ expanded }) => {
                setOpen(
                  produce((draft) => {
                    draft[title] = expanded;
                  })
                );
              }}
              title={<Heading variant={HeadingVariant.H6}>{title}</Heading>}
            >
              <Text>{content}</Text>
            </Accordion>
          );
        })}
      </div>
      <Paragraph className={styles['learn-more']}>
        Still have questions? Learn more <a href={SUPPORT_URL}>here</a>.
      </Paragraph>
    </section>
  );
}
