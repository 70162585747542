export enum BudgetIconVariant {
  ValueProp,
  Bank,
}

interface Props {
  variant: BudgetIconVariant;
  className?: string;
}

export function BudgetIcon(props: Props) {
  const { variant, className } = props;

  switch (variant) {
    case BudgetIconVariant.ValueProp:
      return (
        <svg
          className={className}
          width="49"
          height="49"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M90.7836 51.0417C90.7836 54.6971 85.5419 57.6262 84.6958 61.0023C83.8173 64.5076 86.999 69.6505 85.3298 72.7272C83.6256 75.8684 77.6547 75.8308 75.2564 78.3884C72.8395 80.9659 73.1203 87.056 70.1325 88.8998C67.1667 90.7302 62.0391 87.6453 58.643 88.6138C55.348 89.5534 52.5362 94.9319 48.9583 94.9319C45.3801 94.9319 42.5681 89.5533 39.2729 88.6135C35.8766 87.645 30.7488 90.7296 27.7829 88.8991C24.7952 87.0551 25.0761 80.965 22.6592 78.3874C20.2612 75.8298 14.2905 75.8676 12.5865 72.7265C10.9174 69.65 14.0992 64.5073 13.2209 61.0022C12.3748 57.6261 7.13303 54.6971 7.13303 51.0417C7.13303 47.3862 12.3748 44.4572 13.2209 41.0811C14.0993 37.5758 10.9176 32.4329 12.5868 29.3562C14.291 26.2149 20.2619 26.2526 22.6602 23.6949C25.0772 21.1174 24.7964 15.0274 27.7841 13.1835C30.75 11.3532 35.8775 14.438 39.2737 13.4696C42.5686 12.53 45.3804 7.15149 48.9583 7.15149C52.5365 7.15149 55.3486 12.5301 58.6438 13.4698C62.04 14.4384 67.1678 11.3538 70.1337 13.1843C73.1215 15.0282 72.8405 21.1183 75.2574 23.696C77.6554 26.2535 83.6261 26.2158 85.3302 29.3568C86.9992 32.4334 83.8174 37.5761 84.6958 41.0811C85.5419 44.4572 90.7836 47.3863 90.7836 51.0417Z"
            stroke="#2C3650"
            strokeWidth="4.5"
            strokeMiterlimit="10"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25 58.3334L36.8971 58.3334C37.7667 51.9743 43.3012 47.0708 50 47.0708C56.6988 47.0708 62.2333 51.9743 63.103 58.3334L75 58.3334C74.0867 45.5268 63.2431 35.4167 50 35.4167C36.7569 35.4167 25.9132 45.5268 25 58.3334Z"
            fill="#2C3650"
          />
        </svg>
      );
    case BudgetIconVariant.Bank:
      return (
        <svg
          className={className}
          width="49"
          height="49"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 77H40.5659C41.192 72.0052 45.1769 68.1538 50 68.1538C54.8232 68.1538 58.808 72.0052 59.4341 77H68C67.3424 66.9411 59.5351 59 50 59C40.4649 59 32.6575 66.9411 32 77Z"
            fill="#2C3650"
          />
          <path
            d="M20.1328 87.75L11.7898 49.25H88.2102L79.8672 87.75H20.1328Z"
            stroke="#2C3650"
            strokeWidth="4.5"
          />
          <path
            d="M73.1958 14.3023L79.0837 36.75L21.9163 36.75L27.8042 14.3023C28.1213 13.0931 29.2141 12.25 30.4642 12.25L70.5358 12.25C71.7859 12.25 72.8787 13.0931 73.1958 14.3023Z"
            stroke="#2C3650"
            strokeWidth="4.5"
          />
          <path
            d="M65.2135 21.8116L69.0914 36.75L31.9086 36.75L35.7865 21.8116C35.8723 21.4809 36.1708 21.25 36.5124 21.25L64.4876 21.25C64.8292 21.25 65.1277 21.4809 65.2135 21.8116Z"
            stroke="#2C3650"
            strokeWidth="4.5"
          />
          <mask id="path-5-inside-1_47_1218" fill="white">
            <rect x="7" y="35" width="86" height="16" rx="1" />
          </mask>
          <rect
            x="7"
            y="35"
            width="86"
            height="16"
            rx="1"
            stroke="#2C3650"
            strokeWidth="9"
            mask="url(#path-5-inside-1_47_1218)"
          />
        </svg>
      );
    default:
      return null;
  }
}
