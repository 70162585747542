export function LessSugar() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6011 19.097V3"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M22.8966 13.4167L28.5875 19.1075L34.2784 13.4167"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <rect
        x="8.4224"
        y="10.8749"
        width="8.3682"
        height="8.3682"
        transform="rotate(13.2575 8.4224 10.8749)"
        stroke="#2C3650"
        strokeWidth="2.5"
      />
      <rect
        x="22.5357"
        y="23.5432"
        width="8.3682"
        height="8.3682"
        transform="rotate(55.6085 22.5357 23.5432)"
        stroke="#2C3650"
        strokeWidth="2.5"
      />
    </svg>
  );
}
