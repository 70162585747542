export function Vegetarian() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6024 4.22265C19.6024 4.22265 36.7923 16.7546 20.0361 30.4387C20.0361 30.4387 3.65338 18.5974 19.6024 4.22265Z"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M20 37.9922L20 15.4922"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
