export function Vegetarian() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5229 5.06719C23.5229 5.06719 44.1507 20.1055 24.0434 36.5265C24.0434 36.5265 4.38406 22.3169 23.5229 5.06719Z"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M24 45.5906L24 18.5906"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
