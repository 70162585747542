export function GutFriendly() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11_11772)">
        <path
          d="M26.4575 30.1845C26.4575 30.1845 28.7025 31.0066 29.9346 30.0507C31.1667 29.0949 31.7813 27.0479 33.724 25.9115C35.6666 24.7752 37.4287 22.9284 39.3376 22.2545C41.2464 21.5805 43.0001 19.8493 43.0677 18.9245C43.1354 17.9997 41.8947 16.0646 41.8947 16.0646"
          fill="#EDAB93"
        />
        <path
          d="M26.4575 30.1845C26.4575 30.1845 28.7025 31.0066 29.9346 30.0507C31.1667 29.0949 31.7813 27.0479 33.724 25.9115C35.6666 24.7752 37.4287 22.9284 39.3376 22.2545C41.2464 21.5805 43.0001 19.8493 43.0677 18.9245C43.1354 17.9997 41.8947 16.0646 41.8947 16.0646"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M38.3017 13.5501C37.2322 13.9444 36.0499 15.4329 34.8859 16.0003C34.0511 16.4071 33.2291 16.8399 32.39 17.2377C29.6789 18.5232 22.82 23.697 23.1253 27.204C23.1429 27.4065 23.2963 28.9037 24.4981 29.72C24.6928 29.8522 25.5031 30.366 26.4575 30.1844C27.3483 30.0149 27.8383 29.3397 28.7056 28.2077C30.0924 26.3978 30.7857 25.4928 31.2751 24.957C32.8665 23.2146 34.7158 22.3626 35.7634 21.88C37.0532 21.2857 38.3732 20.8906 40.1158 19.7474C40.4962 19.4979 41.063 19.1036 41.4727 18.3796C41.5881 18.1756 42.1433 17.1943 41.8947 16.0645C41.5145 14.3368 39.4449 13.1287 38.3017 13.5501Z"
          fill="#EDAB93"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M37.1408 19.3221L34.2622 18.3469"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M33.7117 21.4011L30.5764 20.066"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M30.7618 23.5846L28.1389 22.8706"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M27.9822 26.2211L26.0266 25.5724"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M35.9559 28.1253C36.9762 28.0857 37.6426 28.733 37.7837 28.8777C38.0106 29.1105 38.5266 29.6398 38.4031 30.2516C38.2949 30.7884 37.7579 31.0757 37.5669 31.1779C36.5367 31.7292 34.9329 31.484 34.3211 30.4559C33.5348 29.1341 34.8041 28.17 35.9559 28.1253Z"
          fill="#F9EED8"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M42.1909 28.2755C39.6304 25.3277 42.5725 24.8306 43.8261 26.2056C45.3312 27.8562 45.8212 32.5844 42.1909 28.2755Z"
          fill="#F9EED8"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M14.2939 18.8668C14.7761 19.5141 14.9576 20.2905 14.8801 21.0384C14.9548 21.096 15.029 21.154 15.1079 21.204C16.0341 20.8175 17.0804 20.9093 17.9154 21.4373C18.1511 21.3389 18.3814 21.2205 18.5949 21.059C19.9577 20.0279 20.2375 18.0845 19.22 16.7179C18.2024 15.3514 16.2727 15.0795 14.9099 16.1106C14.1928 16.6531 13.7849 17.449 13.6988 18.2799C13.9162 18.4475 14.1216 18.6354 14.2939 18.8668Z"
          fill="#438442"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.98388 18.2594C11.3467 17.2284 13.2763 17.5003 14.2939 18.8668C15.3115 20.2333 15.0317 22.1769 13.6689 23.2079C12.3061 24.2389 10.3764 23.967 9.35884 22.6005C8.34125 21.234 8.62109 19.2904 9.98388 18.2594Z"
          fill="#438442"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.7384 22.1898C17.8169 20.9522 16.1512 20.6236 14.8321 21.3363C14.6864 22.0562 14.2993 22.731 13.6689 23.2078C13.5402 23.3052 13.4026 23.3796 13.2651 23.454C13.0959 24.2904 13.2565 25.189 13.8033 25.9234C14.8209 27.2898 16.7506 27.5617 18.1134 26.5308C19.4761 25.4998 19.756 23.5563 18.7384 22.1898Z"
          fill="#438442"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.2651 23.4539C12.4393 23.9001 11.4842 23.9337 10.6532 23.5928C10.3684 24.1742 10.0743 24.4634 10.0743 24.4634L6.43869 28.7386C6.30499 29.612 7.45097 30.2893 7.45097 30.2893C8.60888 30.72 9.29437 30.1448 9.29437 30.1448L12.2271 27.0485C12.8408 26.4757 13.3998 26.0742 13.7546 25.8403C13.2541 25.1213 13.1019 24.2604 13.2651 23.4539Z"
          fill="#438442"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M16.191 5L22.5203 6.56808C22.5203 6.56808 22.8538 8.2092 24.6498 8.60746C24.6498 8.60746 26.1798 9.10957 27.5318 7.80624L33.8258 9.27784C33.8258 9.27784 31.0377 15.6179 23.4208 13.7309C23.4208 13.7309 16.5425 12.2308 16.191 5Z"
          fill="#C2DA96"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M44.6825 34.0004H2.65337L2.27011 34.0004C2.21052 33.9936 1.98006 34.0783 2.00139 34.3223C2.02958 34.6448 3.16985 39.429 8.06879 42.7441C8.06879 42.7441 14.1383 47.0157 22.4925 47C30.8466 46.9842 35.3539 45.6906 39.2619 43.2363C43.1699 40.7821 45.3849 36.8915 45.9241 34.7824C45.9241 34.7824 46.0745 34.2369 45.9519 34.1344C45.8586 34.0565 45.8056 34.0252 45.5614 34.0004H44.6825Z"
          fill="#93BDDA"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_11772">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
