export function Protein() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.835 28.492C39.9249 28.0383 38.5651 27.4039 36.8623 26.775C34.4978 25.9017 32.1892 25.0689 29.1292 24.9047C28.028 24.8455 26.3739 24.7693 24.3073 25.2725C21.4333 25.9721 19.7848 27.2999 19.4552 27.5722C19.2616 27.7321 19.1064 27.8723 19.0003 27.9708C18.9607 28.0147 18.8424 28.1347 18.6473 28.1746C18.4064 28.224 18.2202 28.1165 18.1767 28.0898C18.1416 27.8071 18.1197 27.5293 18.1095 27.2569C17.94 22.733 20.33 16.0355 23.1014 17.0098C26.4035 18.1707 28.1219 18.3915 28.6993 18.5055C33.3351 19.4205 31.0834 16.2512 33.5752 15.8762C36.5201 15.4329 35.8605 8.78179 34.7241 7.82367C31.8968 5.44001 27.1692 6.48279 23.799 7.54891C12.5864 11.0958 9.30003 27.2167 8.87941 29.4245C8.7319 29.6019 6.68606 32.1425 7.61317 34.935C8.33086 37.0965 10.3754 38.0399 11.2467 38.4418C12.4867 39.0139 13.6212 39.1415 14.3297 39.1653C16.5666 40.4168 20.593 42.2628 25.8911 42.5608C27.9135 42.6747 32.2999 42.8708 37.1771 40.6683C38.6883 39.9859 39.8741 39.2414 40.7006 38.6645"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M33 16C30.5 16.1667 27 15.5 27 12.5"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
