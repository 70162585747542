export function GutFriendly() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5 4V6.64206C27.5 8.4966 29.0034 10 30.8579 10H35.7916C39.5053 10 42.6755 12.6831 43.2894 16.3457V16.3457C43.4288 17.1776 43.4288 18.027 43.2894 18.859V18.859C42.6755 22.5216 39.5053 25.2046 35.7916 25.2046H14.1235C14.0553 25.2046 14 25.2599 14 25.3281V25.3281C14 25.3963 14.0553 25.4516 14.1235 25.4516H29.7073C32.5247 25.4516 35.1111 27.0098 36.428 29.5005L36.6159 29.8559C37.7802 32.058 37.6698 34.7163 36.3267 36.8142V36.8142C35.056 38.7992 32.8615 40 30.5046 40H26.3399C25.1367 40 24.1613 40.9754 24.1613 42.1786V44.5"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M19.7898 4V7.5V7.5C19.7898 13.0228 24.267 17.5 29.7898 17.5H35.2258H13.8496C9.93259 17.5 6.62359 20.4171 6.12233 24.3019V24.3019C6.04119 24.9308 6.03777 25.5684 6.11247 26.1981V26.1981C6.5727 30.0778 9.86209 33 13.769 33H29.4828C29.4938 33 29.5044 33.0044 29.5121 33.0123V33.0123C29.5376 33.0383 29.5192 33.0822 29.4828 33.0822H23.8963C19.8114 33.0822 16.5 36.3936 16.5 40.4784L16.5 44.6452"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
