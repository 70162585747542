export function GutFriendly() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2 4V6.11365C22.2 7.59728 23.4027 8.8 24.8863 8.8H28.8333C31.8043 8.8 34.3404 10.9464 34.8315 13.8765V13.8765C34.9431 14.5421 34.9431 15.2216 34.8315 15.8872V15.8872C34.3404 18.8173 31.8043 20.9637 28.8333 20.9637H11.4988C11.4442 20.9637 11.4 21.0079 11.4 21.0625V21.0625C11.4 21.1171 11.4442 21.1613 11.4988 21.1613H23.9658C26.2198 21.1613 28.2889 22.4078 29.3424 24.4004L29.4927 24.6847C30.4242 26.4464 30.3358 28.573 29.2614 30.2514V30.2514C28.2448 31.8394 26.4892 32.8 24.6037 32.8H21.2719C20.3093 32.8 19.529 33.5803 19.529 34.5429V36.4"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M16.0318 4V6.8V6.8C16.0318 11.2183 19.6136 14.8 24.0318 14.8H28.3806H11.2797C8.14608 14.8 5.49887 17.1337 5.09786 20.2416V20.2416C5.03295 20.7446 5.03022 21.2547 5.08997 21.7585V21.7585C5.45816 24.8622 8.08967 27.2 11.2152 27.2H23.7862C23.795 27.2 23.8035 27.2036 23.8097 27.2099V27.2099C23.8301 27.2306 23.8153 27.2657 23.7862 27.2657H19.317C16.0491 27.2657 13.4 29.9149 13.4 33.1827L13.4 36.5161"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
